<template>
  <div class="c_follow">
    <!-- ShareThis BEGIN -->
    <div v-if="!navigatorShare" class="sharethis-inline-share-buttons"></div>
    <div v-if="navigatorShare" class="share-div">
      <button type="button" class="share-button" @click="share">
        <img alt="share" height="24" src="../assets/share.svg" style="vertical-align: bottom;" />
        SHARE
      </button>
    </div>
    <!-- ShareThis END -->
  </div>
</template>

<script>

const getApi = () => import('@/services/api')

export default {
  name: 'CShareThisInline',
  computed: {
    navigatorShare () {
      return !!navigator.share
    }
  },
  mounted () {
    if (window && window.__sharethis__ && navigator.share) {
      window.__sharethis__.initialize()
    }
  },
  methods: {
    async share () {
      const shareData = {
        title: 'MyPhoneRobot',
        text: 'Spoof Call to change your CALLER ID when calling. Add voice changer for ultimate privacy. ' +
          'Try our free spoof call on this page to protect your personal information when on the phone.',
        url: window.parent ? window.parent.location.href : window.location.href
      }
      navigator.share(shareData)
      const token = await this.$recaptcha('audit')
      const api = await getApi()
      const message = this.$route.fullPath
      const user = this.$store.getters.getEmail
      const pass = this.$store.getters.getPassword
      await api.auditLog({ token, message, user, pass })
    }
  }
}
</script>

<style lang="scss">
.c_follow {
  margin-bottom: 2rem;
}

.share-button {
  background: $primary;
  color: #fff;
  font-size: 1.15em;
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 750px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: none;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
}

.share-div {
  margin: auto;
  text-align: center;
  @include two-column {
    width: 33%;
  }
}

</style>
